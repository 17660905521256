import React from "react";

const About = () => {
  return (
    <div id="about-page">
      <h2 className="info__title">Proximamente</h2>
    </div>
  );
};

export default About;
